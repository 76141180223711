import { Add } from "@mui/icons-material";
import { mainTheme } from "@/services/themes";
import { Avatar, Box, Icon, IconButton, Stack } from "@mui/material";
import _ from 'lodash';

import { openPlanDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector } from '@/services/hooks';
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMemo, useRef, useState } from "react";

interface Props {
  characterId?: number | null,
  swappedCharacterIds?: number[],
  createOnClick: (characterId: number) => () => void,
}

export default function CharacterList(props: Props) {
  const { characterId, createOnClick, swappedCharacterIds } = props;

  const scrollStackRef = useRef<HTMLDivElement>(null);
  const [scrollbarHeight, setScrollbarHeight] = useState(0)


  const { characters } = useSelector((state) => state.character);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { detail } = useSelector((state) => state.user);


  const list = useMemo(() => {
    const myCharacters = characters.filter((character) => {
      return character.is_editable;
    })

    if (!_.isEmpty(myCharacters)) {
      return myCharacters
    }

    return characters.filter((character) => {
      return !character.is_editable && myCharacters.length === 0;
    })

  }, [characters]);

  return (
    <Stack gap={0}>

      <Stack
        ref={scrollStackRef}
        onMouseOver={() => {
          setScrollbarHeight(scrollStackRef.current
            ? scrollStackRef.current.offsetHeight - scrollStackRef.current.clientHeight
            : 0)
        }}
        direction="row"
        gap="5px"
        justifyContent="start-flex"
        sx={{
          overflowX: 'scroll',
          ":not(:hover)": {
            MsOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          ":hover": {
            marginBottom: `-${scrollbarHeight && scrollbarHeight + 0.5}px`, // なぜか0.5px分ガタつくので、0.5px分マイナス
          }
        }}
      >

        <IconButton
          sx={{
            padding: 0,
            border: 'solid 2px transparent',
          }}
          onClick={() => {
            if (detail && detail.character.plan_remain < 1) {
              dispatch(openPlanDialog('character'));
            } else {
              navigateTo('/characters/new');
              // closeDialog();
            }
          }}
        >
          <Avatar
            sx={{
              width: 38,
              height: 38,
              bgcolor: mainTheme.palette.primary.main,
            }}
          >
            <Add fontSize="large" />
          </Avatar>
        </IconButton>
        {
          list.map((character, index) => {
            const isImgesSwitched = _.includes(swappedCharacterIds, character.id);

            return (
              <IconButton
                key={character.id}
                sx={{
                  padding: 0,
                  border: characterId === character.id ? 'solid 2px #F06292' : 'solid 2px transparent',
                }}
                onClick={createOnClick(character.id)}
                disabled={character.is_limited}
              >
                <Avatar
                  alt={character.name}
                  src={character.url}
                  key={index}
                  sx={{
                    width: 38,
                    height: 38,
                    filter: character.is_limited ? 'brightness(0.5)' : 'none',
                  }}
                />
                <Icon
                  sx={{
                    width: '16px',
                    height: '16px',
                    position: 'absolute',
                    right: '-4px',
                    bottom: '-2px',
                    display: !isImgesSwitched ? 'none' : 'inline-block',
                  }}
                >
                  <Box
                    position="relative"
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        borderRadius: '50%',
                        top: '3px',
                        left: '3px',
                        content: '""',
                        display: 'block',
                        width: '11px',
                        height: '11px',
                        backgroundColor: 'white',
                        zIndex: 1,
                      }}
                    />
                    <CheckCircleIcon
                      color="primary"
                      sx={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        fontSize: '16px',
                        zIndex: 2,
                      }}
                    />
                  </Box>
                </Icon>
              </IconButton>
            )
          })
        }
      </Stack>
    </Stack >
  )
}
