import { axios } from '@/services/api';
import { User, UserDetail } from '@/services/models';
import { getSession, setSession } from '@/utils';
import Axios from 'axios';
import { Dispatch } from 'redux';

export const getUserInfo = () => async (dispatch: Dispatch) => {
  dispatch({ type: 'USER_INFO_REQUEST' });

  try {
    const { data } = await axios.get('/api/v1/users/me', {withCredentials: true});
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    if (data.is_rewrited_plan) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(logout())
    }
    return data;
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
    }
  }
};

export const getUserDetail = () => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.get('/api/v1/users/me/detail', {withCredentials: true});
    dispatch({ type: 'USER_DETAIL_SUCCESS', payload: data });
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_DETAIL_FAIL', payload: e.message });
    }
  }
};

export const loginUser = (email: string, password: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token', {
      email: email,
      password: password,
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
    }
    return { success: false }
  }
};

export const getLoginBonus = () => async (dispatch: Dispatch) => {
  try {
    const {data} = await axios.get('/api/v1/login_bonus', {withCredentials: true});
    dispatch({type: 'LOGIN_BONUS_SUCCESS', payload: data.result});
    if (data.is_update || data.is_create) {
      // TODO: 決済復活キャンペーン終了したら消す
      const user: User | undefined = data.user;
      const detail: UserDetail | undefined = data.user_detail;
      const sessionId = 'revival-payment-campaign-dialog';
      const hasBeenShown = !!getSession(sessionId)
      if ((
        detail?.is_compensation_target // 補填対象者
        && user?.is_free_plan // 補填後課金前
      ) || (
        detail?.has_paid // 過去に課金経験あり
        && user?.is_free_plan // 補填後課金前
        && !hasBeenShown // 初回アクセス
      )) {
        setSession(sessionId, 'true');
        dispatch({type: 'OPEN_REVIVAL_PAYMENT_CAMPAIGN_DIALOG'});
        return;
      }
      // ここまで
      dispatch({type: 'OPEN_LOGIN_BONUS_DIALOG'});
    }
  } catch (e: unknown) {
    if (Axios.isAxiosError(e)) {
      dispatch({type: 'LOGIN_BONUS_FAIL', payload: e.message});
    }
  }
};

export const loginGoogleUser = (email: string, socialAccountId: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token/google', {
      email: email,
      social_account_id: socialAccountId
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    let status;
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
      status = e.response?.status;
    }
    return { success: false, status: status }
  }
};

export const loginLineUser = (code: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token/line', {
      code: code,
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    let status;
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
      status = e.response?.status;
    }
    return { success: false, status: status }
  }
};

export const loginXUser = (code: string) => async (dispatch: Dispatch) => {
  try {
    const { data } = await axios.post('/api/v1/token/x', {
      code: code,
    },{ withCredentials: true });
    dispatch({ type: 'USER_INFO_SUCCESS', payload: data });
    return { success: true, data }
  } catch (e: unknown) {
    let status;
    if (Axios.isAxiosError(e)) {
      dispatch({ type: 'USER_INFO_FAIL', payload: e.message });
      status = e.response?.status;
    }
    return { success: false, status: status }
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  try {
    await axios.post('/api/v1/logout', {
    },{ withCredentials: true });
    dispatch({ type: 'USER_LOGOUT' });
  } catch (e: unknown) {
    // logout のサーバー通信が失敗
    // if (axios.isAxiosError(e)) {
    // dispatch({ type: 'USER_DETAILS_FAIL', payload: error.message });
    // }
  }
};
