import _ from "lodash";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Button, Box, Divider, Stack, ButtonBase } from "@mui/material";

import { loadUTC, resolveGenreParam } from "@/utils";
import { Scene } from "@/services/models";
import { openCharacterNewDialog, openSnackbar, openSwitchDialog } from "@/services/actions/commonActions";
import { useDispatch, useSelector, useUrlParams } from '@/services/hooks';
import SwapIcon2 from "@/assets/SwapIcon2.svg";
import SwapIcon from "@/assets/SwapIcon.svg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormattedMessage } from "react-intl";
import LightStreamPlayer from "@/components/LightStreamPlayer/LightStreamPlayer";
import Mask from "@/components/common/Mask";
import SwitchHint from "@/components/common/Hint/SwitchHint";
import TagManager from "react-gtm-module";
import SmallGenreButton from "@/components/common/SmallGenreButton";
import CharacterList from "@/components/Scene/Image/CharacterList";
import { useEffect, useMemo, useState } from "react";
import { ApiService } from "@/services/api";
import ProgressBar from "@/components/Scene/Image/ProgressBar";
import { getLoginBonus } from "@/services/actions/userActions";

interface Props {
  isTutorial?: boolean;
  index?: number;
  scene: Scene;
  setScenes: React.Dispatch<React.SetStateAction<Scene[]>>;
  showDivider: boolean;
  bgColor?: string;
  isShowGenreSearch?: boolean
  sceneType: string
}

const MAX_IMAGE_SWITCH = 10;

export default function SceneWithImageComp(props: Props) {
  const { setUrlParam, getUrlParam } = useUrlParams();
  const { characters } = useSelector((state) => state.character);
  const [characterId, setCharacterId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const { scene, setScenes } = props;
  const { user, daily_trial } = useSelector((state) => state.user);
  const now = loadUTC()
  const postedAt = loadUTC(scene.posted_at)
  const isNew = now.add(-7, 'day').isBefore(postedAt)
  const isSemiNew = !isNew && now.add(-21, 'day').isBefore(postedAt)

  const [isLoading, setIsLoading] = useState(false);

  const imageSiwtchCount = daily_trial?.image_switch_count || 0;

  const [percent, setPercent] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setPercent(d => {
        if (!isLoading) { return 0; }
        return Math.min(d + 16, 99);
      })
    }, 800)
    return () => {
      setPercent(0);
      clearInterval(interval)
    }
  }, [isLoading])

  const sceneImages = useMemo(() => {
    return _.filter(scene?.scene_images, (item) => {
      return item.character_id === characterId;
    });
  }, [scene, characterId]);

  const isSwitchedCharacter = useMemo(() => {
    return _.includes(scene?.swapped_character_ids, characterId);
  }, [scene, characterId]);

  const isImageSwitchable = _.isEmpty(sceneImages);

  useEffect(() => {
    setCharacterId(characters[0]?.id)
  }, [characters]);

  const clickOpenSwitchDialog = () => {
    if (!characterId) {
      dispatch(openCharacterNewDialog());
      return;
    }
    // switch後ボタンを暗くするのに使用
    const onClose = () => {
      setScenes((before) => {
        const next = [...before];
        const sceneIndex = _.findIndex(next, { id: scene.id });
        if (sceneIndex !== -1) {
          const newScene = { ...scene };
          if (newScene.swapped_character_ids) {
            newScene.swapped_character_ids = [...newScene.swapped_character_ids, characterId as number];
          } else {
            newScene.swapped_character_ids = [characterId as number];
          }
          next[sceneIndex] = newScene;
          return next;
        }
        return before;
      })
    }
    dispatch(openSwitchDialog(scene, characterId, onClose));
  }

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          position: 'relative',
          backgroundColor: props.bgColor,
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          marginTop={2}
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          width="100%"
        >
          {scene.title}

          {scene.point === 0 && (<Box
            display="inline-flex"
            gap="4px"
            alignItems="center"
            sx={{
              minWidth: '48.8px',
              borderRadius: '4px',
              border: '1px solid #4CAF50',
              padding: '1px 2px',
            }}
          >
            <img src={SwapIcon2} alt="swap icon" width="14px" />
            <Typography variant="caption" color="#4CAF50" >
              <FormattedMessage id="free" />
            </Typography>
          </Box>)}
        </Typography>

        {
          props.isShowGenreSearch && !scene.is_tutorial && (isNew || isSemiNew || !!scene.genres?.length) && (
            <Box
              padding={0}
              display="flex"
              alignItems="center"
              overflow="scroll"
              gap="6px"
              mt="12px"
            >
              <Typography
                variant="body1"
                fontSize="12px"
                flexShrink={0}
                sx={{
                  color: '#000000DE',
                  fontWeight: 400,
                }}
              >タグ：</Typography>
              <Box
                display='flex'
                flexWrap='wrap'
                gap="6px"
              >
                {
                  isNew && (
                    <SmallGenreButton
                      key="new"
                      label="新作"
                      onClick={() => {
                        setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), '新作'));
                      }}
                    />
                  )
                }
                {
                  isSemiNew && (
                    <SmallGenreButton
                      key="semiNew"
                      label="準新作"
                      onClick={() => {
                        setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), '準新作'));
                      }}
                    />
                  )
                }
                {
                  _.map(scene.genres, (genre) => {
                    return (
                      <SmallGenreButton
                        key={genre?.id}
                        label={genre?.name}
                        onClick={() => {
                          setUrlParam('filter', resolveGenreParam(getUrlParam('filter'), genre?.name));
                        }}
                      />
                    )
                  })
                }
              </Box>
            </Box>
          )
        }

        <Box
          mt={2}
          display="flex"
          alignItems="start"
          justifyContent="center"
          gap={1}
        >
          <Box width="calc(50% - 4px)">
            <LightStreamPlayer
              is_tutorial={scene.is_tutorial}
              scene_id={scene.id}
              src={scene.uid}
              is_limited={scene.character?.is_limited}
              thumbnail_start_sec={scene.thumbnail_start_sec}
              scene_type={props.sceneType}
              filter={props.isShowGenreSearch ? getUrlParam('filter') || undefined : undefined}
            />
          </Box>

          <Stack
            width="calc(50% - 4px)"
            display="flex"
            gap="12px"
          >
            <Stack
              gap="10px"
            >
              <CharacterList
                characterId={characterId}
                swappedCharacterIds={scene?.swapped_character_ids}
                createOnClick={(cId: number) => {
                  return () => {
                    setCharacterId(cId);
                  }
                }}
              />

              {isImageSwitchable && (
                <>
                  <Box>
                    <Button
                      onClick={() => {
                        if (!characterId) {
                          dispatch(openCharacterNewDialog());
                          return;
                        }
                        setIsLoading(true);
                        ApiService.switchImage(characterId, scene.id).then((data) => {
                          setScenes((before) => {
                            const originalSceneId = data[0]?.original_scene_id;
                            const next = [...before];
                            const sceneIndex = _.findIndex(next, { id: originalSceneId });
                            if (sceneIndex !== -1) {
                              const newScene = { ...scene };
                              newScene.scene_images = newScene.scene_images || [];
                              newScene.scene_images = [...newScene.scene_images, ...data];
                              next[sceneIndex] = newScene;
                              return next;
                            }
                            return before;
                          })
                          // TODO
                          const el = <FormattedMessage id="scene.image.dialog_message1" />
                          dispatch(openSnackbar(el, 'bottom'));
                        }).catch((err) => {
                          const el = <FormattedMessage id="scene.image.dialog_error_message" />
                          dispatch(openSnackbar(el, 'bottom'));
                          console.log(err);
                        }).finally(() => {
                          dispatch(getLoginBonus());
                          setIsLoading(false);
                        })
                      }}
                      size="small"
                      color="primary"
                      variant="contained"
                      disabled={isLoading || MAX_IMAGE_SWITCH - imageSiwtchCount <= 0}
                      sx={{
                        width: '100%',
                        height: '30px',
                        fontSize: '14px',
                        paddingX: 0,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#FFFFFF',
                          fontSize: '12px',
                          lineHeight: '22px',
                          fontWeight: 500,
                          letterSpacing: '0.46px',
                        }}
                      >
                        <FormattedMessage id="scene.image.trial.button" />
                        <Box
                          display="inline"
                          sx={{ fontSize: '10px' }}
                        >
                          {MAX_IMAGE_SWITCH - imageSiwtchCount}/{MAX_IMAGE_SWITCH}
                        </Box>
                      </Typography>
                    </Button>
                  </Box>

                  {!!percent && (
                    <ProgressBar
                      value={percent}
                    />
                  )}

                  <Box>
                    <Mask
                      open={props.isTutorial && props.index === 0}
                      zIndex={100}
                    />
                    <Button
                      onClick={() => {
                        TagManager.dataLayer({
                          dataLayer: {
                            event: props.isTutorial ? "tutorial_click_lets_switch_button" : "click_lets_switch_button",
                            user_id: user?.id,
                          }
                        });
                        clickOpenSwitchDialog();
                      }}
                      size="small"
                      color="inherit"
                      sx={{
                        width: '100%',
                        zIndex: props.isTutorial ? 101 : 1,
                        postion: 'relative',
                        height: '30px',
                        fontSize: '14px',
                      }}
                      endIcon={<ArrowForwardIosIcon
                        color="primary"
                        sx={{
                          paddingTop: '2px',
                          fontSize: '12px !important',
                        }}
                      />}
                    >
                      <Typography
                        color="textPrimary"
                        sx={{
                          fontSize: '12px',
                          lineHeight: '15px',
                          fontWeight: 400,
                        }}
                      >
                        <FormattedMessage id="scene.image.instant.switch.button" />
                      </Typography>
                      {props.isTutorial && props.index === 0 ? <SwitchHint /> : null}
                    </Button>
                  </Box>
                </>
              )}
              {
                !isImageSwitchable && (
                  <ButtonBase>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      gap={1}
                      padding="8px 10.5px"
                      sx={{
                        background: '#F5F5F5',
                        border: 'solid 1px #0000001F',
                      }}
                      onClick={() => {
                        clickOpenSwitchDialog();
                      }}
                    >
                      <Stack
                        sx={{
                          flexGrow: 1,
                        }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <img
                          src={sceneImages[0].s3_url}
                          style={{
                            width: '100%',
                          }}
                        />
                      </Stack>
                      <Typography
                        color="primary"
                        fontSize="11px"
                        sx={{
                          minWidth: 'fit-content',
                        }}
                      >
                        <FormattedMessage id="scene.image.view_image" />
                      </Typography>
                    </Box>
                  </ButtonBase>
                )
              }
            </Stack>
          </Stack>
        </Box>

        {/* この顔でスイッチする */}
        {!isImageSwitchable && (
          <Stack
            justifyContent="start"
            gap={1}
          >
            <Typography variant="body2">
              <FormattedMessage id="scene.image.use_point" />{"："}{scene.point}
            </Typography>

            <Button
              variant="contained"
              startIcon={
                <img
                  src={SwapIcon}
                  alt="swap icon"
                  width="18px"
                  style={{
                    filter: isSwitchedCharacter ? 'brightness(0.65)' : 'none',
                  }}
                />
              }
              onClick={() => {
                clickOpenSwitchDialog();
              }}
              disabled={isSwitchedCharacter}
            >
              <FormattedMessage id={isSwitchedCharacter ? "scene.image.switched_button" : "scene.image.switch_button"} />
            </Button>
          </Stack>
        )}

      </Card>
      {props.showDivider && <Divider sx={{ mt: 2 }} />}
    </>
  );
}
