export default {
  "cancel": "Cancel",
  "free": "Free",
  "alert_modal.close": "Close",
  "hint.switch.how_to_use": "Usage Tips",
  "hint.switch.lets": "Let's switch faces!",
  "hint.switch.caption": "You can try this mark for free",
  "hint.switch_view.lets": "The Switched video will be displayed here!",
  "hint.switch_view.caption": "It can take a little while for the video to actually be Switched ❤️",
  "hint.character.lets": "et's make an original AV with your favorite face image!",
  "hint.character.caption": "Upload your favorite face image and create a character to create your own AV! ❤️",
  "hint.close_hint": "Stop showing hints",
  "header.signup": "Sign Up (Free)",
  "login_bonus.title": "Login Bonus",
  "login_bonus.title_1": "Free daily (up to 10 times)",
  "login_bonus.title_2": "Trial Image Switch",
  "login_bonus.limited": "Only available now!",
  "login_bonus.text": "Log in daily and check the quality of the Switch video with images!",
  "login_bonus.button": "Search for videos to Switch",
  "switch.dialog.warning": "Images are for illustration purposes only. Actual content may vary.",
  "switch.dialog.remain_point": "Current remaining points",
  "switch.dialog.use_point": "Points to be consumed",
  "switch.dialog.switch_button": "Switch with This Face",
  "switch_image.dialog.switch_button": "Switch",
  "switch.dialog.button.limited_item_1": "Switch at a great price",
  "switch.dialog.button.limited_item_2": "first only",
  "switch.dialog.button.upgrade": "Upgrade and Switch",
  "switch.dialog.button.item": "Buy points（$7）",
  "switch.dialig.switch.caution": "*Note: Your videos/faces will not be made public.*",
  "scene.image.dialog_message1": "A trial image has been generated.",
  "scene.image.dialog_error_message": "Unknown error occrued. Please try again later",
  "scene.image.trial.button": "Trial Image Switch",
  "scene.image.instant.switch.button": "Instant Video Switch",
  "scene.image.view_image": "View Trial Image",
  "scene.image.use_point": "Points to be consumed",
  "scene.image.switch_button": "Switch with This Face",
  "scene.image.switched_button": "Switched",
  "sidebar.login": "Login",
  "sidebar.logged_in": "Logged in",
  "sidebar.logout": "Logout",
  "sidebar.home": "Home",
  "sidebar.mypage": "My Page",
  "sidebar.videos": "Video List",
  "sidebar.characters": "My Character",
  "sidebar.faq": "FAQ",
  "sidebar.contact": "Contact",
  "sidebar.notice": "Notice",
  "sidebar.terms": "Terms of Use",
  "sidebar.policy": "Privacy policy of Use",
  "sidebar.law": "Legal Notice (Specified Commercial Transactions Act)",
  "sidebar.other": "Other",
  "notice.title": "Notice",
  "notice.view.title": "Message",
  "notice.view.back": "Back",
  "notice.empty": "No notice found",
  "notice.all_check": "All read",
  "character.created": "Character created",
  "character_list.recommend_character": "Recommended Characters",
  "character_list.my_character": "My Characters",
  "character_list.character_create": "Create a Character",
  "current_plan.current_plan": "Current Plan",
  "current_plan.plan": "Plan",
  "current_plan.character": "My Character",
  "current_plan.character_description": "（Current / Maximum）",
  "current_plan.view": "View",
  "current_plan.favorite": "Favorite",
  "current_plan.favorite_description": "（Current / Maximum）",
  "current_plan.switch_point": "Switch Points",
  "current_plan.switch_point_description": "（Subscribe Purchase + Additional Purchase）",
  "current_plan.change_plan": "Change Plan",
  "current_plan.downgrade_plan": "Downgrade to Free Plan",
  "current_plan.change_plan_success": "Successfully downgraded to the free plan",
  "current_plan.change_plan_failed": "Failed to downgrade to the free plan",
  "character_delete.title": "Delete a Character",
  "character_swap.lack_point": "Lack of points.",
  "character_delete.message": "Deleting will result in the loss of all of the following information for this character",
  "character_delete.detail1": "Switched video",
  "character_delete.detail2": "Registered character image",
  "character_delete.detail3": "Videos in Favorites",
  "character_delete.cancel": "Cancel",
  "character_delete.delete": "Delete",
  "character_swap.title": "Select a character to Switch",
  "character_swap.message1": "It will consume Switch Points to Switch a video. Would you like to proceed?",
  "character_swap.message2": "Current remaining points",
  "character_swap.message3": "Points to be consumed",
  "character_swap.downgrade.message1": "If you sign up for a paid plan again, you will be able to watch videos and Switch the disabled characters.",
  "character_swap.one_time_item": "Purchase a small amount of items (limited to one purchase)",
  "character_swap.one_time_item.text": "Switch now!",
  "home.upgrade_banner.button": "Upgrade",
  "home.upgrade_banner.text": "Payment will not be made until payment confirmation is completed on the next screen or later.",
  "home.bottom_upgrade_banner.text_01": "Over {num} works!",
  "home.bottom_upgrade_banner.text_02": "New items are being added every week!",
  "home.bottom_upgrade_banner.description": "Enjoy Switch with more videos!",
  "home.bottom_upgrade_banner.button": "Upgrade to Switch",
  "character_swap.upgrade": "Upgrade and Switch",
  "character_swap.upgrade.text": "Switch with more videos!",
  "character_swap.tutorial_generate": "Switch",
  "character_swap.generate": "Switch with Points",
  "character_swap.button_comment": "The video/face will not be made public.",
  "character_swap.buy_item": "Buy Points",
  "chracter_new_dialog.description": "First, let's register the face image to be replaced!",
  "chracter_new_dialog.button": "Register a face image",
  "confirm_dialog.generating": "Switching...",
  "confirm_dialog.create": "Create",
  "confirm_dialog.cancel": "Cancel",
  "delete_dialog.cancel": "Cancel",
  "delete_dialog.generating": "Deleting...",
  "info_dialog.ok": "OK",
  "plan_downgrade.title": "If you change to the free plan",
  "plan_downgrade.mes_01-1": "Remaining expiration date",
  "plan_downgrade.mes_01-2": "The service is available until the end of the month.",
  "plan_downgrade.mes_02": "At the end of the expiration date, the number of characters will be limited to one (the first character) and the number of generated video favorites will be zero.",
  "plan_downgrade.mes_03": "Videos generated with disabled characters cannot be viewed.",
  "plan_downgrade.mes_04": "The remaining Switch points will no longer be available.",
  "plan_downgrade.mes_05": "If you sign up for a paid plan again, you will be able to watch videos and Switch the disabled characters.",
  "plan_downgrade.tell_reason": "Please enter the reason for the downgrade",
  "plan_downgrade.reason": "The reason",
  "plan_downgrade.please_enter": "Please enter the reason",
  "plan_downgrade.cancel": "Cancel",
  "plan_downgrade.yes": "Change",
  "plan_dialog.play": "Play",
  "plan_dialog.switch": "Switch",
  "plan_dialog.character": "Character",
  "plan_dialog.favorite": "Favorite",
  "plan_dialog.limit": "You have reached the limit",
  "plan_dialog.upgrade": "Upgrade Plan",
  "plan_dialog.close": "Close",
  "registration_dialog.message": "In order to use FACESWITCH, user registration is required.",
  "registration_dialog.registration": "Register as a User",
  "registration_dialog.close": "Close",
  "feed.input_name": "Enter Name",
  "age_confirm.title": "Age Confirmation",
  "age_confirm.age_confirm": "Are you over 18 years old?",
  "age_confirm.message1": "From this point forward, the site will be an adult site dealing in adult products.",
  "age_confirm.message2": "Access by persons under 18 years of age is strictly prohibited.",
  "age_confirm.yes": "Yes",
  "age_confirm.no": "No",
  "character.edit.update_complete": "Update completed",
  "character.edit.error": "Error",
  "character.edit.delete_complete": "Deletion completed",
  "character.edit.title": "Edit Character",
  "character.edit.input_name": "Enter Name",
  "character.edit.complete": "Complete",
  "character.edit.delete_character": "Delete Character",
  "character.edit.cancel": "Cancel",
  "character.index.title": "Character Management",
  "character.index.free_plan": "Free Plan",
  "character.index.my_character": "My Characters",
  "character.index.new_character_create": "Create a New Character",
  "character.new.title": "Create Character",
  "character.new.error_face_not_found": "Face not found. Please try again with a different image.",
  "character.new.message1": "Add an image of the character",
  "character.new.upload": "Upload Image",
  "character.new.white": "Whitening",
  "character.new.skin": "Skin Beautifying",
  "character.new.face": "Slim Face",
  "character.new.eye": "Eyes",
  "character.new.nose": "Nose",
  "character.new.rip": "Lips",
  "character.new.input_name": "Enter Name",
  "character.new.publish": "Set to Public",
  "character.new.generating": "Generating...",
  "character.new.add_tag": "Add Tags (optional)",
  "character.new.create_character": "Create Character",
  "character.new.create_character_comment": "Uploaded images will not be made public.",
  "character.new.cancel": "Cancel",
  "character.new.error": "Error",
  "character.new.ok": "OK",
  "character.view.scene_upload": "Upload Video",
  "character.view.not_found": `There is no video you have switched.
Go back to Home and Switch.`,
  "contact.title": "Contact",
  "contact.name": "Name",
  "contact.mail": "Email Address",
  "contact.content": "Content of Inquiry",
  "contact.submit": "Submit",
  "contact.send_success": "Contact send success",
  "contact.send_failed": "Error: send failed",
  "faq.title": "Frequently Asked Questions",
  "faq.q_1": "I would like to confirm whether minors can apply.",
  "faq.q_2": "What is FACESWITCH Account?",
  "faq.q_3": "I want to check the services (plans) currently in use.",
  "faq.q_4": "How to check my purchase history.",
  "faq.q_5": "Forgot Login ID/Password",
  "faq.q_6": "How to change my login ID/password.",
  "faq.q_7": "It shows that entered email address is not registered.",
  "faq.q_8": "I haven't received the email.",
  "faq.q_9": "Emails are not being received (Cannot receive emails on my mobile carrier's email)",
  "faq.q_10": "How to cancel (downgrade to the free plan).",
  "faq.q_11": "What are points?",
  "faq.q_12": "About fees.",
  "faq.q_13": "Monthly plan fees.",
  "faq.q_14": "Available payment methods.",
  "faq.q_15": "I want to cancel the billing for paid items.",
  "faq.q_16": "What are points?",
  "faq.q_17": "Would there be an expiration date for points?",
  "faq.a_1": `Minors cannot register as members without the consent of their legal guardians or underage supervisors.

This is stipulated in the terms of use, and agreement with the terms is required during the registration process.
  
*By applying, it is considered that users have agreed to the terms, and we will assume that the application has the consent of the legal guardian or underage supervisor.`,
  "faq.a_2": `■ What is an Account?

It is the authentication information you register to use FACESWITCH services.
■ What is Authentication Information?
  
It refers to the login ID/password.
■ Information Associated with the Account
＜Status of Various Monthly Services in Use＞
  
It can be associated if you have any contracted services.
Services that are associated can be used.
= Examples of Monthly Services =
  
★ Monthly Plan
  
＜Rights to View Paid Content＞
  
Rights and expiration dates for various paid content can be associated.
＜Paid Content＞
  
Character creation
Image Switch
Number of video views
Number of favorites
＜Customer Information＞
  
Various customer information you provide during registration can be associated.
= Examples of Customer Information =
  
★ Name
★ Email address
★ Security code
★ Payment-related information, etc.
  
■ How to Use the Account
  
Even if you cancel all monthly services, you can continue to use the account alone.
= What You Can Do with the Account Alone =
  
★ View free videos
  
Moreover, you can restart monthly services according to your preferences.
*Note: The reason why the account cannot be deleted when there are existing contracted monthly services is because of this.`,
  "faq.a_3": `■ Confirmation Method
1: Log in to the FACESWITCH website.
2: Select "[My Page]" from the menu.`,
  "faq.a_4": `1: Select ［My Page］ from the menu.
2: Choose ［Purchase History］.
3: Purchase history will be displayed.`,
  "faq.a_5": `■ Introduction
＜Text format errors＞
  
Please check for any errors in your input.
When entering your Login ID/Password, please use alphanumeric characters in half-width.
  
※ Uppercase and lowercase are identified as different characters.
  
＜Failure to update stored passwords on the device＞
  
Information from before the password change may be stored in the browser, causing login failures when using autofill. Avoid using autofill.
■ If you cannot resolve the issue
・You can confirm your Login ID and reset your password using the following methods.
  
■ Confirmation and Reset Method
1: Display the login screen.
2: Select ［Forgot your password?］.
3: A dedicated form will appear.
4: Enter the registered email address.
5: Select ［Send reset email］.
6: An email will be sent to the address entered in step 4.
7: Confirm the steps in the email.
8: Confirm your Login ID and reset your password.
  
Please log in with the updated information.
  
※ Sent from the @face-switch-ai.com domain.
※ If you see "The entered email address is not registered," click here.
  
■ Unable to reset due to URL expiration
・ Resend the email from step 1 of the confirmation and reset method.
  
■ Unable to reset due to non-receipt of email
・ Please check your spam folder.
  
■ Unable to reset due to an unusable email address
★ Forgot the registered email address
★ The registered email address is no longer usable
  
・ If you encounter these cases, please contact us.`,
  "faq.a_6": `Please refer to 'Forgot Login ID/Password.'`,
  "faq.a_7": `When confirming or changing your login ID/password and undergoing identity verification, please send the confirmation email to your registered email address.`,
  "faq.a_8": `This is a page for customers who have registered Gmail or other email addresses.

■ Reasons for not receiving emails
・ There may be issues preventing reception due to the following:
  
＜Anti-spam measures＞
・ Automatically classified into the spam folder.
  
＜Overcapacity＞
・ Exceeding the maximum email reception capacity.
  
＜Settings＞
・ Errors in the values set in the email software.
  
※ This also includes cases of missed updates after password changes.
  
■ Remedies
＜Folder Check＞
・ Please check the reception status of all folders, including the spam folder.
  
※ For example, in the case of Gmail, you may need to perform certain actions to display the spam folder.
  
※ Please check with the provider for specifications of the email application you are using.
  
＜Data Capacity Check＞
・ There is a limit to the capacity for storing received emails, including Gmail.
  
※ If the limit is exceeded, you won't be able to receive emails, so it's necessary to delete unnecessary data.
※ Here is an explanation related to Gmail.
  
■ Domain Specification
・ Register the following domain in the allowed reception list.
  
■ Domain to be set
@face-switch-ai.com`,
  "faq.a_9": `
  ■ Reasons for not receiving emails
  ・ If the following settings are in place for anti-spam measures, emails may not be received.
  
  ＜Domain settings＞
  ・ Only allowing reception of emails from specific domains.
  
  ＜PC rejection settings＞
  ・ Rejecting email reception from computers.
  
  ＜Security settings＞
  ・ Increasing the strength of anti-spam measures provided by mobile phone companies.
  
  ■ Remedies
  ・ Register the following domain in the allowed reception list.
  
  ■ Domain to be set
  @face-switch-ai.com
  
  
  ■ Unlock URL-linked email rejection settings
  ・ Please unlock the URL-linked email rejection settings.`,
  "faq.a_10": `1: Select [My Page] from the menu.
2: Choose [Management] next to the plan.
3: Select 'Downgrade to Free Plan.'
4: Confirm the precautions and choose 'Yes.'`,
  "faq.a_11": `- What are Points?
You can acquire points that can be used for Switch by purchasing points.
  
Charging Points
Select your desired plan and purchase items from [My Page] > [Current Plan].
  
Current Point Balance
Check your current point balance from [My Page] > Buy Points.
  
Point Purchase History
You can review your point purchase history from [My Page] > [Purchase History].`,
  "faq.a_12": `Monthly Plan

<Monthly Usage Fee>
The monthly fee is $15 (including tax).
Paid Items
  
Examples:
  
Character creation
Switch per month
Video viewing per month
Favorites`,
  "faq.a_13": `Monthly Plan

  <Monthly Usage Fee>
  $15`,
  "faq.a_14": `■ Credit Card
・ The following brands are accepted.
・ Payments for all charges are possible.
  
★ VISA
★ Master Card
★ JCB
  
※ Invoices will be issued by AI-Pioneer to the customers.`,
  "faq.a_15": `Purchases such as point charges and other paid items cannot be canceled after billing.`,
  "faq.a_16": `Items are the additional points required to Switch and perform a new facial switch.
  "10 packages" of switch is worth about 10 minutes, and "100 packages" of switch is worth about 100 minutes.`,
  "faq.a_17": `Additionally purchased points will be valid for the first 6 months after the purchase.`,
  "law.title": "Notations are based on the Specified Commercial Transaction Act",
  "terms.title": "Terms of Use",
  "policy.title": "Privacy Policy",
  "demo.title": "FACESWITCH",
  "demo.free_demo": "Free Demo",
  "demo.lets_text1": "With you favorite face",
  "demo.lets_text2": "let's create your ideal AV!",
  "demo.step1_text": "Select face",
  "demo.step2_text": "Select scene",
  "demo.result": "Switch Result",
  "demo.display_result": "Switched video will be displayed here",
  "demo.enjoy": "Let's make an original AV with your favorite face image!",
  "demo.button1": "What FACESWITCH can do for you",
  "demo.button2": "30 second video on how to use",
  "demo.movielist": "An example of a video",
  "demo.upgrade": "Upgrade and Switch",
  "demo.register": "Register now for free user registration",
  "demo.login": "Click here to log in",
  "home.title": "FACESWITCH",
  "demo.scene_title": "Video Viewing",
  "demo.scene_description": "Unlimited",
  "demo.max_character": "Max Characters",
  "demo.free_plan_max_character_num": "1 person",
  "demo.subscription_max_character_num": "20 people",
  "demo.switch_title": "FACESWITCH Video",
  "demo.plan.switch_description1": "2000point",
  "demo.plan.switch_description2": "(About 25min)/month",
  "demo.switch_description": "About 25 min/month",
  "demo.favorite_title": "Add Favorite",
  "demo.favorite_description": "95 videos",
  "demo.parallel_switch_title": "Parallel Switch",
  "demo.parallel_switch_description": "2 parallels",
  "demo.av_title": "Full AV Viewing",
  "demo.av_description": "Unlimited",
  "demo.modal_description": "ace Switch is a video generation service that allows you to switch faces with your favorite face images.",
  "demo.modal_free": "free membership",
  "demo.modal_not_free": "paid membership",
  "demo.modal_button_up":"Register to become a free member",
  "demo.modal_button":"user registration (free of charge)",
  "demo2.description.text1": 'A video generation service that allows you to switch to your favorite face image and enjoy it',
  "demo2.description.text2": 'FACESWITCH is a video creation service that lets you switch faces with your favorite face images and enjoy the results.',
  "demo2.description.text3": 'Create your own original AV using your personal photos.',
  "demo2.description.text4": 'Upload your favorite face image, select a video from a wide range of options, and create your own original AV.',
  "demo2.description.text5": 'Three simple steps to create your own original AV',
  "demo2.description.text6": 'Movie list',
  "demo2.description.text7": 'Exciting new videos coming soon!',
  "home.recommend": "Recommended",
  "home.favorite": "Favorites",
  "home.empty_favorite_free": "Paid plans allow the use of the favorites feature.",
  "home.empty_favorite": "No favorites registered yet.",
  "home.empty_favorite2": "You need to switch a video to register a favorite.",
  "home.switched": "Switch",
  "home.empty_switched": "No switches registered yet.",
  "home.empty_switched_image": "No switched images registered yet.",
  "home.load_more": "Load More",
  "home.switch_trial_text": "You can try the video Switch feature for free with the 100 points provided (about one video). With a paid plan, you can use the Switch feature for more videos.",
  "mypage.title": "My Page",
  "mypage.user_info": "User Information",
  "mypage.mail": "Email Address",
  "mypage.register_mail": "Register your email address",
  "mypage.register_mail.text": "Required when purchasing points.",
  "mypage.register_mail.success": "Email address registered",
  "mypage.register_mail.attention": "Please register your email address.",
  "mypage.password": "Password",
  "mypage.password.none": "Password / not set",
  "mypage.password.attention": "If you set password, you can use email login",
  "mypage.password.attention.no_mail": "Please register your email address first",
  "mypage.other_info": "Other Information",
  "mypage.current_plan": "Current Plan",
  "mypage.purchase_history": "Purchase History",
  "mypage.plan_and_point": "Plan and Points",
  "mypage.sample_scene.before": "With a paid membership, the following videos can also be switched from one to the next.",
  "mypage.sample_scene": "Other switches for various scenes are possible.",
  "mypage.law": "Notations are based on the Specified Commercial Transaction Act",
  "videos.title": "Video List",
  "videos.caption1": "Unlimited streaming of paid plan videos",
  "videos.loading": "Loading...",
  "videos.free_description": "With a paid plan, you can watch unlimited full porn videos.",
  "videos.free_button": "Upgrade and watch the video",
  "scene.enjoy": "Switch face in this video!",
  "scene_image.enjoy": "Video Switch with this face!",
  "scene.add.confirm1": "Creating scene.",
  "scene.add.confirm2": "Once a scene is created, only the title and caption can be edited.",
  "scene.add.warning1": "If 'Do not Switch face' is turned on,",
  "scene.add.warning2": "The character becomes public and the uploaded image/video is viewable by others as is.",
  "scene.add.warning3": "Please review the content guidelines for uploaded images/videos.",
  "scene.add.scene_create": "Create Video",
  "scene.add.scene_title": "Video Title",
  "scene.add.add_movie": "Add Video",
  "scene.add.create": "Create",
  "scene.add.generate": "Switch",
  "scene.add.cancel": "Cancel",
  "scene.edit.delete_message": "Are you sure you want to delete this character?",
  "scene.edit.edit_scene": "Edit Video",
  "scene.edit.scene_title": "Video Title",
  "scene.edit.generating": "Generating...",
  "scene.edit.save": "Save",
  "scene.edit.delete_scene": "Delete Video",
  "scene.edit.cancel": "Cancel",
  "scene.feedback.ask.title": `Please let us know
what you think of the switched videos!`,
  "scene.feedback.ask.description": "We will use this as a reference for future quality improvements.",
  "scene.feedback.good.title": `I'm glad you were satisfied🎉
Would you like to start by paying a small amount
to enjoy Switch even more?`,
  "scene.feedback.good.minutes": "Points（equivalent {minutes} minutes）",
  "scene.feedback.good.subtitle": `If you want to switch
with more videos, Bronze plan`,
  "scene.feedback.good.plan_button": "Check the plan details",
  "scene.feedback.bad.title": `Not meeting your expectations
I'm sorry`,
  "scene.feedback.bad.present1": "We've given away",
  "scene.feedback.bad.present2": "one free video Switch",
  "scene.feedback.bad.present3": "to you,",
  "scene.feedback.bad.present4": "so please try it again!",
  "scene.feedback.bad.tip_title": "Tips for successfully Switching",
  "scene.feedback.bad.tip_description1": "Use the filtering function to select videos with hairstyles and body types similar to the character you created.",
  "scene.feedback.bad.tip_description2": "Choose a character with clear facial features",
  "user.login.title": "Login",
  "user.login.already_login1": "You are already logged in",
  "user.login.already_login2": "Redirecting",
  "user.login.input_login_info": "Please input your login information",
  "user.login.login_id": "Login ID (Email Address)",
  "user.login.password": "Password",
  "user.login.password_label": "Password",
  "user.login.forget_password1": "If you forgot your password,",
  "user.login.forget_password2": "click here",
  "user.login.login": "Login",
  "user.login.signup": "Sign Up",
  "user.login.signup_with_google": "Signup with Google",
  "user.login.signup_with_x": "Signup with X",
  "user.login.signup_with_line": "Signup with LINE",
  "user.login.google": "Google",
  "user.login.x": "X",
  "user.login.line": "LINE",
  "user.login.already_exist": "Email is already exist.",
  "user.login.login_failed": "Wrong email address or password.",
  "user.login.login_failed_google": "Google account user does not exist.",
  "user.login.login_failed_x": "X account user does not exist.",
  "user.login.login_failed_line": "LINE account user does not exist.",
  "user.login.resend_password_mail": "Password reset email has been sent",
  "user.login.password_change_done": "Password has been changed",
  "user.email_registration.title": "Email Registration",
  "user.email_registration.description": "Please enter your email address.",
  "user.email_registration.mail": "Email Address",
  "user.email_registration.submit": "Register Email Address",
  "user.email_registration.send": "A verification email has been sent. Please complete the registration by following the URL in the email. If you do not receive the email, please also check your spam folder.",
  "user.email_registration.submit_message": "Please enter your email address.",
  "user.password_recovery.length_error": "The password must be at least 8 characters long",
  "user.password_recovery.invalid_password": "Passwords do not match",
  "user.password_recovery.invlaid_token": "Invalid token",
  "user.password_recovery.expired": "The URL has expired. Please reset your password again.",
  "user.password_recovery.title": "Password Change",
  "user.password_recovery.description": "Please enter the password to register.",
  "user.password_recovery.password_label1": "Password to register",
  "user.password_recovery.password_label2": "Password to register (reenter)",
  "user.password_recovery.save": "Save with above contents",
  "user.password_reset.title": "Password Change",
  "user.password_reset.description": "Please enter your registered email address. We will send a link to reset your password.",
  "user.password_reset.mail": "Email Address",
  "user.password_reset.submit": "Send Reset Email",
  "user.purchase.history.title": "Purchase History",
  "user.purchase.history.empty": "No purchase history found",
  "user.plan.about_switched_scene_streaming.title": "Regarding the playback of switched scenes",
  "user.plan.about_switched_scene_streaming.description1": "{bold1} following the Switch, all videos except for your favorites will be archived, and {bold2}.",
  "user.plan.about_switched_scene_streaming.description1.bold1": "After 45 days",
  "user.plan.about_switched_scene_streaming.description1.bold2": "loading times may increase for rewatching",
  "user.plan.about_switched_scene_streaming.description2": "{bold1} allows you to watch them {bold2}.",
  "user.plan.about_switched_scene_streaming.description2.bold1": "Adding switched videos to your favorites",
  "user.plan.about_switched_scene_streaming.description2.bold2": "quickly at any time",
  "user.plan.comparison": "Plan Comparision",
  "user.plan.title": "Plans",
  "user.plan.per_month": "/ Month",
  "user.plan.plan_list": "Available Plans (monthly)",
  "user.plan.feature": "Available Features",
  "user.plan.character.label": "Face Upload",
  "user.plan.character.unit": "Characters",
  "user.plan.swap.label": "Switch Point / Month",
  "user.plan.swap.unit": "Point（of 25min）",
  "user.plan.swap.unit_after": "（of 25min）",
  "user.plan.item.can_buy_point": "Points available for purchase",
  "user.plan.item.count": "time",
  "user.plan.item.limit": "Limited",
  "user.plan.buy_item.button": "Purchase {point}Points",
  "user.plan.av.label": "Full AV Viewing",
  "user.plan.av.free": "Not available",
  "user.plan.av.paid": "unlimited",
  "user.plan.view.label": "Video Views / Month",
  "user.plan.view.unit": "Videos",
  "user.plan.favorite.label": "Added to Favorites",
  "user.plan.favorite.unit": "Favorites",
  "user.plan.parallel_switch.label": "Maximum number of parallel switches",
  "user.plan.parallel_switch.unit": "Parallels",
  "user.plan.plan_suffix": "Plan",
  "user.plan.buy_plan": "Purchase Plan",
  "user.plan_complete.title": "Purchase Completed",
  "user.plan_complete.home": "SWITCH immediately",
  "user.plan_complete.mypage": "My Page",
  "user.plan_failed.title": "Purchase Failed",
  "user.plan_failed.home": "Home",
  "user.plan_failed.mypage": "My Page",
  "user.plan.free.bronze_can": "What you can do with the Bronze Plan",
  "user.plan.free.point_title": "3 Reliable Points",
  "user.plan.free.point1_text1": "Multi-device support",
  "user.plan.free.point1_text2": "You can enjoy it on your PC or on your smartphone!",
  "user.plan.free.point2_text1": "Enrollment at the end of the month is also acceptable",
  "user.plan.free.point2_text2": "You can use it for one month from the date of payment, so you can enroll at any time!",
  "user.plan.free.point3_text1": "No need to worry about cancellations",
  "user.plan.free.point3_text2": "You can continue to enjoy the service until the renewal date even after the cancellation procedure is completed.",
  "user.plan.free.announce": "Important Notice",
  "user.plan.free.announce_description": `This service is automatically renewed on a monthly basis based on the date of payment.
  If you do not wish to receive automatic monthly renewal, please cancel your subscription before the expiration date.
  Please check the free sample before purchasing.`,
  "user.plan.free.button_caption": "Payment will not be made until payment confirmation is completed on the next screen or later.",
  "user.plan.bronze.downgraded": "Already applied to change to a free plan",
  "user.plan.bronze.downgrade.info": "At the end of the expiration date, the plan will automatically change to a free plan.",
  "user.plan.bronze.limit_time": "Expiration date",
  "user.plan.bronze.day": "day",
  "user.signup.already_signup": "Registration failed.",
  "user.signup.success": "Registration completed.",
  "user.signup.social.already_exist": "Email is already exist.",
  "user.signup.social.error": "Error occurred during registration.",
  "user.signup.success_message": `You have received an authentication email. Please complete your registration by clicking on the URL in the email.\n
  If you don't receive the email, please also check your spam folder.`,
  "user.signup.title": "User Registration",
  "user.signup.description": "Please input your registration information",
  "user.signup.login_id": "Login ID (Email Address)",
  "user.signup.password": "Password",
  "user.signup.age": "Age",
  "user.signup.age_10": "18-19 years old",
  "user.signup.age_20": "20-29 years old",
  "user.signup.age_30": "30-39 years old",
  "user.signup.age_40": "40-49 years old",
  "user.signup.age_50": "50-59 years old",
  "user.signup.age_60": "60-69 years old",
  "user.signup.age_70": "70-79 years old",
  "user.signup.age_80": "80 years old -",
  "user.signup.age_no": "Not answered",
  "user.signup.policy": "Privacy Policy",
  "user.signup.sep": " and ",
  "user.signup.terms": "Terms of Use ",
  "user.signup.policy_agree": "Agree to",
  "user.signup.register": "Register",
  "user.signup.other_account": "Signup with Other Account",
  "user.signup.to_login": "Here to Login",
  "user.signup_plan.title": "Plan",
  "user.signup_plan.description1": "User registration is complete.",
  "user.signup_plan.description2": "To purchase a plan, you need to register a credit card.",
  "user.signup_plan.regist_cregit": "Register a Credit Card",
  "user.signup_plan.next": "Proceed as Free User",
  "common.invalid.email_format": "Invalid email format",
  "common.invalid.email_alias": "Alias is not allowed",
  "common.invalid.email": "Invalid email address",
  "common.register.failed": "Registration failed",
  "common.name.required": "Name is required",
  "common.content.required": "Content is required",
  "common.password.invalid_length": "Password must be at least 8 characters",
  "common.token.expired": "Token has expired",
  "common.token.invalid": "Invalid token",
  "generate.in_queue": "Waiting",
  "generate.in_progress": "Switching",
  "generate.in_uploading": "Uploading",
  "generate.failed": "Filed",
  "generate.complete": "Video is created. Please visit SWITCH tab",
  "generate.error.success": "Start switching",
  "generate.error.ongoing": "This switch is already ongoing",
  "generate.error.regenerating": "Retrying the last switch",
  "generate.error.already_exists": "The switched scene already exists",
  "generate.error.over_parallel_limit": "Maximum number of switches reached",
  "generate.error.unknown": "Unknown error occrued. Please try again later",
  "login.timeout": "Timed out. Redirecting to login...",
  "login.loading": "Loading...",
  "login.not_login": "Not logged in.",
  "video_stream.upgrade.required": "A paid plan must be purchased for viewing.",
  "video_stream.upgrade.button": "Upgrade",
  "category.play": "Play",
  "category.female_category": "Type",
  "category.genre": "Genre",
  "category.situation": "Situation",
  "category.hairstyle": "Hairstyle",
  "category.publish_date": "Delivery start date",
  "search.recommend": "Recommend",
  "search.latest": "Latest",
  "search.popularity": "Popularity",
  "guest.scene.subtitle": "Scene List",
  "guest.scene.dialog.description": "Sign up is required to use.",
  "guest.scene.dialog.signup": "Sign Up (Free)",
  "maintenance.title": "Under Maintenance",
  "maintenance.description": "We are currently under maintenance. We apologize for any inconvenience caused. Please access again later.",
  "maintenance.description_long": "We are currently experiencing a maintenance delay beyond our initial expectations. Please try accessing the service again later. Once the maintenance is complete, we will inform you via our newsletter. We apologize for any inconvenience caused."
}
