import _ from 'lodash';
import { closeSwitchDialog, openAlertModal, openSnackbar, setProgressPolling } from "@/services/actions/commonActions";
import { useDispatch, useNavigateWithQueryAndHash, useSelector } from "@/services/hooks";
import { Avatar, Box, Button, CardHeader, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { drawerWidth } from '@/constants';
import { useEffect, useMemo, useState } from 'react';
import { Item, SceneImage } from '@/services/models';
import { color } from '@/services/themes';
import TagManager from 'react-gtm-module';
import { ApiService } from '@/services/api';
import { startProgressSession } from '@/services/progress';
import { getUserDetail } from '@/services/actions/userActions';
import { getRelativeTime } from '@/utils';


export default function SwitchDialog() {
  const dispatch = useDispatch();
  const navigateTo = useNavigateWithQueryAndHash();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);

  const { user, detail } = useSelector((state) => state.user);
  const remain = _.get(detail, 'swap.item_remain', 0) + _.get(detail, 'swap.plan_remain', 0)

  const { switch_dialog } = useSelector((state) => state.common);
  const usePoint = switch_dialog.scene?.point || 0;
  const isSwitchTutorial = switch_dialog.scene?.is_tutorial;
  const isLackPoint = remain < usePoint && !isSwitchTutorial;
  const disabled = !switch_dialog.character_id || isLackPoint || loading;
  const { characters } = useSelector((state) => state.character)
  const [items, setItems] = useState<Item[]>([]);

  const closeDialog = () => {
    dispatch(closeSwitchDialog());
  };

  useEffect(() => {
    if (!switch_dialog.open) {
      return;
    }
    dispatch(getUserDetail())
  }, [switch_dialog.open]);

  useEffect(() => {
    ApiService.fetchItems().then((data) => {
      setItems(data);
    })
  }, [user])

  const appealButton = useMemo(() => {
    if (!isLackPoint) {
      return null;
    }
    if (_.some(items, (item) => {
      return item.purchase_limit === 1 && item.price === '2.00';
    })) {
      return (
        <Button
          variant="contained"
          color="tertiary"
          onClick={() => {
            closeDialog()
            navigateTo('/mypage')
          }}
        >
          <FormattedMessage id="switch.dialog.button.limited_item_1" />
          （{<FormattedMessage id="switch.dialog.button.limited_item_2" />}
          &nbsp;
          <Box display="inline" sx={{ color: '#FFF78C' }}><Box display="inline" sx={{ textDecoration: 'line-through' }}>$7</Box> ⇒$2</Box>
          ）
        </Button>
      )
    }
    if (user?.is_free_plan) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            closeDialog()
            navigateTo('/users/plan')
          }}
        >
          <FormattedMessage id="switch.dialog.button.upgrade" />
        </Button>
      )
    }
    return (
      <Button
        variant="contained"
        color="tertiary"
        onClick={() => {
          closeDialog()
          navigateTo('/mypage')
        }}
      >
        <FormattedMessage id="switch.dialog.button.item" />
      </Button>
    )
  }, [user, items, detail, switch_dialog, isLackPoint]);

  const character = useMemo(() => {
    return _.find(characters, (item) => {
      return item.id === switch_dialog.character_id;
    })
  }, [characters, switch_dialog.character_id])


  const sceneImage: SceneImage | null = useMemo(() => {
    const sceneImages = switch_dialog.scene?.scene_images;
    if (!sceneImages) {
      return null;
    }
    for (const item of sceneImages) {
      if (item.character_id === switch_dialog.character_id) {
        return item;
      }
    }
    return null;

  }, [switch_dialog.character_id, switch_dialog.scene, switch_dialog.open])

  const submitGTM = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "swap",
        user_id: user?.id,
        is_free_plan: user?.is_free_plan,
        scene_id: switch_dialog.scene?.id,
        is_tutorial: switch_dialog.scene?.is_tutorial,
      }
    });
  }

  const handleSubmit = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const response = await ApiService.generate(Number(switch_dialog.character_id), switch_dialog.scene?.id as number);
      if (!response.success) {
        const errorMessage = formatMessage({ id: `generate.error.${response.error_type}`, defaultMessage: "Error" });
        // 特定のエラーの場合はモーダルで表示
        if (response.error_type === 'over_parallel_limit') {
          dispatch(openAlertModal(errorMessage));
        } else {
          dispatch(openSnackbar(errorMessage));
        }
      } else {
        switch_dialog.onClose();
        closeDialog();
        submitGTM()
        dispatch(setProgressPolling(true));
        startProgressSession(); // sessionに開始したことを保存
        dispatch(getUserDetail());
        navigateTo(`/?switched=true`);
      }
    } catch (error: unknown) {
      console.log("generated error: ", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      onClose={closeDialog}
      open={switch_dialog.open}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: '343px',
          width: '100%',
          margin: '16px',
        },
        "& .MuiDialog-container": {
          sm: { marginLeft: `${drawerWidth}px` }
        },
      }}
    >
      <DialogContent
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          padding: 0,
        }}
      >
        <Stack gap={2} p={2}>
          {
            sceneImage ? (
              <Stack width="100%" gap={1}>
                <img src={sceneImage.s3_url} style={{ width: '100%' }} />
                <Typography
                  fontSize="10px"
                  textAlign="center"
                >
                  <FormattedMessage id="switch.dialog.warning" />
                </Typography>
              </Stack>
            ) : (
              <CardHeader
                sx={{ padding: 0 }}
                avatar={
                  <Avatar
                    src={character?.url}
                    sx={{ width: 61, height: 61 }}
                  />
                }
                title={character?.name}
                {...{ subheader: getRelativeTime(character?.created_at as string) }}
              />
            )
          }
          <Box
            p={2}
            sx={{
              backgroundColor: '#F2F2F2',
            }}
          >
            <Typography
              variant="subtitle1"
              lineHeight={1}
              textAlign="center"
            >
              <FormattedMessage id="switch.dialog.remain_point" />{"："}
              <Box
                sx={{
                  color: color.main,
                  fontFamily: 'Roboto',
                  display: 'inline'
                }}
              >
                {remain}
              </Box>
            </Typography>
          </Box>

          <Typography variant="body1">
            <FormattedMessage id="switch.dialog.use_point" />{"："}{switch_dialog.scene?.point}
          </Typography>

          <Stack gap={1}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={disabled}
              onClick={handleSubmit}
            >
              <FormattedMessage id="switch.dialog.switch_button" />
            </Button>
            <Typography variant="caption">
              <FormattedMessage id="switch.dialig.switch.caution" />
            </Typography>
            {appealButton}
          </Stack>
        </Stack>

      </DialogContent>
      <DialogActions
        sx={{ padding: 0 }}
      >

        <Button
          variant="text"
          color="primary"
          size="medium"
          onClick={() => {
            closeDialog();
          }}
        >
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}